/* tslint:disable */
// prettier-ignore
import OtherStudentNotificationComponent
  from "@/entities/other-student-notification/other-student-notification.component";

const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Menu = () => import('@/entities/menu/menu.vue');
// prettier-ignore
const MenuUpdate = () => import('@/entities/menu/menu-update.vue');
// prettier-ignore
const Course = () => import('@/entities/course/course.vue');
// prettier-ignore
const CourseUpdate = () => import('@/entities/course/course-update.vue');
// prettier-ignore
const ClassRoom = () => import('@/entities/class-room/class-room.vue');
// prettier-ignore
const ClassRoomUpdate = () => import('@/entities/class-room/class-room-update.vue');
// prettier-ignore
const Student = () => import('@/entities/student/student.vue');
// prettier-ignore
const StudentUpdate = () => import('@/entities/student/student-update.vue');
// prettier-ignore
const Price = () => import('@/entities/price/price.vue');
// prettier-ignore
const PriceUpdate = () => import('@/entities/price/price-update.vue');
// prettier-ignore
const OrderTimeUpdate = () => import('@/entities/order-time/order-time-update.vue');
// prettier-ignore
const Order = () => import('@/entities/order/order.vue');
// prettier-ignore
const Statistics = () => import('@/entities/statistics/statistics.vue');
// prettier-ignore
const Notification = () => import('@/entities/notification/notification.vue');
// prettier-ignore
import SchoolNotification from '@/entities/school-notification/school-notification.vue';
// prettier-ignore
const AttendanceTime = () => import('@/entities/attendance-time/attendance-time.vue');
// prettier-ignore
const NotificationControlUpdate = () => import('@/entities/notification-control/notification-control-update.vue');
// prettier-ignore
const SchoolLevel = () => import('@/entities/school-level/school-level.vue');
// prettier-ignore
const SchoolLevelUpdate = () => import('@/entities/school-level/school-level-update.vue');
// prettier-ignore
const ContactHour = () => import('@/entities/contact-hour/contact-hour.vue');
// prettier-ignore
const ContactHourUpdate = () => import('@/entities/contact-hour/contact-hour-update.vue');
// prettier-ignore
const Subject = () => import('@/entities/subject/subject.vue');
// prettier-ignore
const SubjectUpdate = () => import('@/entities/subject/subject-update.vue');
// prettier-ignore
const Teacher = () => import('@/entities/teacher/teacher.vue');
// prettier-ignore
const TeacherUpdate = () => import('@/entities/teacher/teacher-update.vue');
// prettier-ignore
const HolidaySchedule = () => import('@/entities/holiday-schedule/holiday-schedule.vue');
// prettier-ignore
const HolidayScheduleUpdate = () => import('@/entities/holiday-schedule/holiday-schedule-update.vue');
// prettier-ignore
const Timetable = () => import('@/entities/timetable/timetable.vue');
const Invoice = () => import('@/entities/invoice/invoice.vue');
const PaymentCategory = () => import('@/entities/payment-category/payment-category.vue');
const PaymentCategoryUpdate = () => import('@/entities/payment-category/payment-category-update.vue');
const SchoolYear = () => import('@/entities/school-year/school-year.vue');
const SchoolYearUpdate = () => import('@/entities/school-year/school-year-update.vue');
const Semester = () => import('@/entities/semester/semester.vue');
const SemesterUpdate = () => import('@/entities/semester/semester-update.vue');
const ScoringScale = () => import('@/entities/scoring-scale/scoring-scale.vue');
const ScoringScaleUpdate = () => import('@/entities/scoring-scale/scoring-scale-update.vue');
const ScoringType = () => import('@/entities/scoring-type/scoring-type.vue');
const ScoringTypeUpdate = () => import('@/entities/scoring-type/scoring-type-update.vue');
const ScoreGroup = () => import('@/entities/score-group/score-group.vue');
const ScoreGroupUpdate = () => import('@/entities/score-group/score-group-update.vue');
const TeachingAssignment = () => import('@/entities/teaching-assignment/teaching-assignment.vue');
const SubjectScore = () => import('@/entities/subject-score/subject-score.vue');
const LectureSchedule = () => import('@/entities/lecture-schedule/lecture-schedule.vue');
const TeachingSchedule = () => import('@/entities/teaching-schedule/teaching-schedule.vue');
const AdditionalTeachingSchedule = () => import('@/entities/additional-teaching-schedule/additional-teaching-schedule.vue');
const ScoreReportTeacher = () => import('@/entities/score-report-teacher/score-report-teacher.vue');
const ImportScore = () => import('@/entities/import-score/import-score.vue');
const ScoreLock = () => import('@/entities/score-lock/score-lock.vue');
const ScoreLockUpdate = () => import('@/entities/score-lock/score-lock-update.vue');
const TimetableTeacher = () => import('@/entities/timetable-teacher/timetable-teacher.vue');
const OrderDelete = () => import('@/entities/order-delete/order-delete.vue');
const Feedback = () => import('@/entities/feedback/feedback.vue');
const RewardAndDisciplineReport = () => import('@/entities/reward-and-discipline-report/reward-and-discipline-report.vue');
const UnfulfilledTeachingScheduleReport = () =>
  import('@/entities/unfulfilled-teaching-schedule-report/unfulfilled-teaching-schedule-report.vue');
const MaxHeightLayout = () => import('@/layout/max-height.layout.vue');
const ScrollHeightLayout = () => import('@/layout/scroll-height.layout.vue');
const TimetableAllCourseReport = () => import('@/entities/timetable-all-course-report/timetable-all-course-report.vue');
const NewsCategory = () => import('@/entities/news-category/news-category.vue');
const NewsCategoryUpdate = () => import('@/entities/news-category/news-category-update.vue');
const Tag = () => import('@/entities/tag/tag.vue');
const TagUpdate = () => import('@/entities/tag/tag-update.vue');
const News = () => import('@/entities/news/news.vue');
const NewsUpdate = () => import('@/entities/news/news-update.vue');
const HomePage = () => import('@/entities/home/home-page.vue');
const EducationDepartmentScore = () => import('@/entities/education-department-score/education-department-score.vue');
const OtherStudentNotification = () => import('@/entities/other-student-notification/other-student-notification.vue');
const NotificationTeacher = () => import('@/entities/notification-teacher/notification-teacher.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: '',
      name: 'ScrollHeightLayout',
      component: ScrollHeightLayout,
      children: [
        {
          path: 'price',
          name: 'Price',
          component: Price,
          meta: { functional: '000601' },
        },
        {
          path: 'price/new',
          name: 'PriceCreate',
          component: PriceUpdate,
          meta: { functional: '00060101' },
        },
        {
          path: 'price/:priceId/edit',
          name: 'PriceEdit',
          component: PriceUpdate,
          meta: { functional: '00060102' },
        },
        {
          path: 'order-time',
          name: 'OrderTime',
          component: OrderTimeUpdate,
          meta: { functional: '000602' },
        },
        {
          path: 'menu',
          name: 'Menu',
          component: Menu,
          meta: { functional: '000603' },
        },
        {
          path: 'menu/new',
          name: 'MenuCreate',
          component: MenuUpdate,
          meta: { functional: '00060301' },
        },
        {
          path: 'menu/:menuId/edit',
          name: 'MenuEdit',
          component: MenuUpdate,
          meta: { functional: '00060302' },
        },
        {
          path: 'order',
          name: 'Order',
          component: Order,
          meta: { functional: '000604' },
        },
        {
          path: 'statistics',
          name: 'Statistics',
          component: Statistics,
          meta: { functional: '000605' },
        },
        {
          path: 'school-year/new',
          name: 'SchoolYearCreate',
          component: SchoolYearUpdate,
          meta: { functional: '00020101' },
        },
        {
          path: 'school-year/:schoolYearId/edit',
          name: 'SchoolYearEdit',
          component: SchoolYearUpdate,
          meta: { functional: '00020102' },
        },
        {
          path: 'semester/new',
          name: 'SemesterCreate',
          component: SemesterUpdate,
          meta: { functional: '00020201' },
        },
        {
          path: 'semester/:semesterId/edit',
          name: 'SemesterEdit',
          component: SemesterUpdate,
          meta: { functional: '00020202' },
        },
        {
          path: 'school-level/new',
          name: 'SchoolLevelCreate',
          component: SchoolLevelUpdate,
          meta: { functional: '00020301' },
        },
        {
          path: 'school-level/:schoolLevelId/edit',
          name: 'SchoolLevelEdit',
          component: SchoolLevelUpdate,
          meta: { functional: '00020302' },
        },
        {
          path: 'course/new',
          name: 'CourseCreate',
          component: CourseUpdate,
          meta: { functional: '00020401' },
        },
        {
          path: 'course/:courseId/edit',
          name: 'CourseEdit',
          component: CourseUpdate,
          meta: { functional: '00020402' },
        },
        {
          path: 'class-room',
          name: 'ClassRoom',
          component: ClassRoom,
          meta: { functional: '000205' },
        },
        {
          path: 'class-room/new',
          name: 'ClassRoomCreate',
          component: ClassRoomUpdate,
          meta: { functional: '00020501' },
        },
        {
          path: 'class-room/:classRoomId/edit',
          name: 'ClassRoomEdit',
          component: ClassRoomUpdate,
          meta: { functional: '00020502' },
        },
        {
          path: 'student/:studentId/:classId/edit',
          name: 'StudentEdit',
          component: StudentUpdate,
          meta: { functional: '00050102' },
        },
        {
          path: 'teacher',
          name: 'Teacher',
          component: Teacher,
          meta: { functional: '000502' },
        },
        {
          path: 'teacher/new',
          name: 'TeacherCreate',
          component: TeacherUpdate,
          meta: { functional: '00050202' },
        },
        {
          path: 'teacher/:teacherId/edit',
          name: 'TeacherEdit',
          component: TeacherUpdate,
          meta: { functional: '00050203' },
        },
        {
          path: 'holiday-schedule',
          name: 'HolidaySchedule',
          component: HolidaySchedule,
          meta: { functional: '000208' },
        },
        {
          path: 'holiday-schedule/new',
          name: 'HolidayScheduleCreate',
          component: HolidayScheduleUpdate,
          meta: { functional: '00020801' },
        },
        {
          path: 'holiday-schedule/:holidayScheduleId/edit',
          name: 'HolidayScheduleEdit',
          component: HolidayScheduleUpdate,
          meta: { functional: '00020802' },
        },
        {
          path: 'notification',
          name: 'Notification',
          component: Notification,
          meta: { functional: '000801' },
        },
        {
          path: 'school-notification',
          name: 'SchoolNotification',
          component: SchoolNotification,
          meta: { functional: '000802' },
        },
        {
          path: 'notification-control',
          name: 'NotificationControlUpdate',
          component: NotificationControlUpdate,
          meta: { functional: '000803' },
        },
        {
          path: 'attendance-time',
          name: 'AttendanceTime',
          component: AttendanceTime,
          meta: { functional: '000804' },
        },
        {
          path: 'invoice',
          name: 'Invoice',
          component: Invoice,
          meta: { functional: '000702' },
        },
        {
          path: 'payment-category',
          name: 'PaymentCategory',
          component: PaymentCategory,
          meta: { functional: '000701' },
        },
        {
          path: 'payment-category/new',
          name: 'PaymentCategoryCreation',
          component: PaymentCategoryUpdate,
          meta: { functional: '00070101' },
        },
        {
          path: 'payment-category/:id/edit',
          name: 'PaymentCategoryEdit',
          component: PaymentCategoryUpdate,
          meta: { functional: '00070102' },
        },
        {
          path: 'scoring-scale',
          name: 'ScoringScale',
          component: ScoringScale,
          meta: { functional: '000301' },
        },
        {
          path: 'scoring-scale/new',
          name: 'ScoringScaleCreate',
          component: ScoringScaleUpdate,
          meta: { functional: '00030101' },
        },
        {
          path: 'scoring-scale/:scoringScaleId/edit',
          name: 'ScoringScaleEdit',
          component: ScoringScaleUpdate,
          meta: { functional: '00030102' },
        },
        {
          path: 'scoring-type',
          name: 'ScoringType',
          component: ScoringType,
          meta: { functional: '000302' },
        },
        {
          path: 'scoring-type/new',
          name: 'ScoringTypeCreate',
          component: ScoringTypeUpdate,
          meta: { functional: '00030201' },
        },
        {
          path: 'scoring-type/:scoringTypeId/edit',
          name: 'ScoringTypeEdit',
          component: ScoringTypeUpdate,
          meta: { functional: '00030202' },
        },
        {
          path: 'score-group',
          name: 'ScoreGroup',
          component: ScoreGroup,
          meta: { functional: '000303' },
        },
        {
          path: 'score-group/new',
          name: 'ScoreGroupCreate',
          component: ScoreGroupUpdate,
          meta: { functional: '00030301' },
        },
        {
          path: 'score-group/:scoreGroupId/edit',
          name: 'ScoreGroupEdit',
          component: ScoreGroupUpdate,
          meta: { functional: '00030302' },
        },
        {
          path: 'contact-hour',
          name: 'ContactHour',
          component: ContactHour,
          meta: { functional: '000206' },
        },
        {
          path: 'contact-hour/:contactHourId/edit',
          name: 'ContactHourEdit',
          component: ContactHourUpdate,
          meta: { functional: '00020601' },
        },
        {
          path: 'subject',
          name: 'Subject',
          component: Subject,
          meta: { functional: '000207' },
        },
        {
          path: 'subject/new',
          name: 'SubjectCreate',
          component: SubjectUpdate,
          meta: { functional: '00020701' },
        },
        {
          path: 'subject/:subjectId/edit',
          name: 'SubjectEdit',
          component: SubjectUpdate,
          meta: { functional: '00020702' },
        },
        {
          path: 'teaching-assignment',
          name: 'TeachingAssignment',
          component: TeachingAssignment,
          meta: { functional: '000401' },
        },
        {
          path: 'timetable',
          name: 'Timetable',
          component: Timetable,
          meta: { functional: '000402' },
        },
        {
          path: 'score-report-teacher',
          name: 'ScoreReportTeacher',
          component: ScoreReportTeacher,
          meta: { functional: '000901' },
        },
        {
          path: 'import-score',
          name: 'ImportScore',
          component: ImportScore,
          meta: { functional: '000305' },
        },
        {
          path: 'score-lock',
          name: 'ScoreLock',
          component: ScoreLock,
          meta: { functional: '000306' },
        },
        {
          path: 'score-lock/new',
          name: 'ScoreLockCreate',
          component: ScoreLockUpdate,
          meta: { functional: '00030601' },
        },
        {
          path: 'score-lock/:scoreLockId/edit',
          name: 'ScoreLockEdit',
          component: ScoreLockUpdate,
          meta: { functional: '00030602' },
        },
        {
          path: 'order-delete',
          name: 'OrderDelete',
          component: OrderDelete,
          meta: { functional: '000606' },
        },
        {
          path: 'feedback',
          name: 'Feedback',
          component: Feedback,
          meta: { functional: '000503' },
        },
        {
          path: 'unfulfilled-teaching-schedule-report',
          name: 'UnfulfilledTeachingScheduleReport',
          component: UnfulfilledTeachingScheduleReport,
          meta: { functional: '000902' },
        },
        {
          path: 'timetable-all-course-report',
          name: 'TimetableAllCourseReport',
          component: TimetableAllCourseReport,
          meta: { functional: '000907' },
        },
        {
          path: 'news-category',
          name: 'NewsCategory',
          component: NewsCategory,
          meta: { functional: '001101' },
        },
        {
          path: 'news-category/new',
          name: 'NewsCategoryCreate',
          component: NewsCategoryUpdate,
          meta: { functional: '00110101' },
        },
        {
          path: 'news-category/:newsCategoryId/edit',
          name: 'NewsCategoryEdit',
          component: NewsCategoryUpdate,
          meta: { functional: '00110102' },
        },
        {
          path: 'tag',
          name: 'Tag',
          component: Tag,
          meta: { functional: '001102' },
        },
        {
          path: 'tag/new',
          name: 'TagCreate',
          component: TagUpdate,
          meta: { functional: '00110201' },
        },
        {
          path: 'tag/:tagId/edit',
          name: 'TagEdit',
          component: TagUpdate,
          meta: { functional: '00110202' },
        },
        {
          path: 'news',
          name: 'News',
          component: News,
          meta: { functional: '001103' },
        },
        {
          path: 'news/new',
          name: 'NewsCreate',
          component: NewsUpdate,
          meta: { functional: '00110301' },
        },
        {
          path: 'news/:newsId/edit',
          name: 'NewsEdit',
          component: NewsUpdate,
          meta: { functional: '00110302' },
        },
        {
          path: 'home-page',
          name: 'HomePage',
          component: HomePage,
          meta: { functional: '001104' },
        },
        {
          path: 'other-student-notification',
          name: 'OtherStudentNotification',
          component: OtherStudentNotification,
          meta: { functional: '000805' },
        },
        {
          path: 'notification-teacher',
          name: 'NotificationTeacher',
          component: NotificationTeacher,
          meta: { functional: '000806' },
        },
      ],
    },
    {
      path: '',
      name: 'MaxHeightLayout',
      component: MaxHeightLayout,
      children: [
        {
          path: 'school-year',
          name: 'SchoolYear',
          component: SchoolYear,
          meta: { functional: '000201' },
        },
        {
          path: 'semester',
          name: 'Semester',
          component: Semester,
          meta: { functional: '000202' },
        },
        {
          path: 'school-level',
          name: 'SchoolLevel',
          component: SchoolLevel,
          meta: { functional: '000203' },
        },
        {
          path: 'course',
          name: 'Course',
          component: Course,
          meta: { functional: '000204' },
        },

        {
          path: 'student',
          name: 'Student',
          component: Student,
          meta: { functional: '000501' },
        },
        {
          path: 'subject-score',
          name: 'SubjectScore',
          component: SubjectScore,
          meta: { functional: '000304' },
        },
        {
          path: 'education-department-score',
          name: 'EducationDepartmentScore',
          component: EducationDepartmentScore,
          meta: { functional: '000307' },
        },
        {
          path: 'lecture-schedule',
          name: 'LectureSchedule',
          component: LectureSchedule,
          meta: { functional: '000403' },
        },
        {
          path: 'timetable-teacher',
          name: 'TimetableTeacher',
          component: TimetableTeacher,
          meta: { functional: '000405' },
        },
        {
          path: 'teaching-schedule',
          name: 'TeachingSchedule',
          component: TeachingSchedule,
          meta: { functional: '000404' },
        },
        {
          path: 'additional-teaching-schedule',
          name: 'AdditionalTeachingSchedule',
          component: AdditionalTeachingSchedule,
          meta: { functional: '000404' },
        },
        {
          path: 'reward-and-discipline-report',
          name: 'RewardAndDisciplineReport',
          component: RewardAndDisciplineReport,
          meta: { functional: '000902' },
        },
      ],
    },
  ],
};
